:root {
  --toastify-toast-min-height: 56px;
  --toastify-toast-max-height: 56px;
}

html {
  background: #111015;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

@font-face {
  font-family: Avenir300;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Light.woff2') format('woff2'),
    url('../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Light.woff') format('woff');
}

@font-face {
  font-family: Avenir400;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.woff2') format('woff2'),
    url('../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.woff') format('woff');
}

@font-face {
  font-family: Avenir500;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Medium.woff2') format('woff2'),
    url('../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Medium.woff') format('woff');
}

@font-face {
  font-family: Avenir600;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url('../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Demi.woff2') format('woff2'),
    url('../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Demi.woff') format('woff');
}

@font-face {
  font-family: Avenir700;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Bold.woff2') format('woff2'),
    url('../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Bold.woff') format('woff');
}

@font-face {
  font-family: Avenir800;
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url('../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Heavy.woff2') format('woff2'),
    url('../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Heavy.woff') format('woff');
}

.Toastify__toast-container--top-center {
  width: 100%;
  top: 0;
  padding: 0;
  margin: 0;
}

.Toastify__toast {
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  margin: 0;
  padding: 0;
}
.Toastify__toast-body {
  margin: 0;
  padding: 0;
}

.loader {
  position: absolute;
  z-index: 1;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  animation: rotate 2s linear infinite;
}

.loader::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 8px solid white;
  animation: prixClipFix 3s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  50% {
    clip-path: polygon(
      50% 50%,
      0 0,
      100% 0,
      100% 100%,
      100% 100%,
      100% 100%
    );
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}
